import { GetStaticProps, GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { createClient } from "../../prismicio";
import Home from "./home";

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}: GetStaticPropsContext) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("page_accueil");
  const promotions = await client.getSingle("homepage");
  const slider = await client.getSingle("block_slider");
  const reviews = await client.getAllByType("avis");

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "validation",
        "header",
        "footer",
      ])),
      page,
      slider,
      reviews,
      promotions,
    },
  };
};

export default Home;
